import { createRouter, createWebHistory } from 'vue-router'
// import Home from '../views/Home.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Home.vue'),
    redirect:"/login",
    children:[
      {
        path: '/stat', //统计概览
        name: 'stat',
        component: () => import('@/views/stat/index.vue')
      },
      {
        path: '/filling/movement', //目录填报--初始化页面
        name: 'movement',
        component: () => import('@/views/dataFilling/movement.vue')
      },
      {
        path: '/filling/explain', //目录填报--上传声明文件
        name: 'explain',
        component: () => import('@/views/dataFilling/explain.vue')
      },
      {
        path: '/filling/submited', //目录填报--上传报送文件
        name: 'submited',
        component: () => import('@/views/dataFilling/submited.vue')
      },
      {
        path: '/filling/fillSuccess', //目录填报--上传报送文件成功
        name: 'fillSuccess',
        component: () => import('@/views/dataFilling/fillSuccess.vue')
      },
      {
        path: '/filling/FillingList', //目录填报
        name: 'FillingList',
        component: () => import('@/views/dataFilling/index.vue')
      },
      {
        path: '/filling/commonData',//常规填报-一般数据
        name: 'commonData',
        component: () => import('@/views/dataFilling/commonData.vue')
      },
      {
        path: '/filling/importantData',//常规填报-重要数据
        name: 'importantData',
        component: () => import('@/views/dataFilling/importantData.vue')
      },
      {
        path: '/filling/coreData',//常规填报-核心数据
        name: 'coreData',
        component: () => import('@/views/dataFilling/coreData.vue')
      },
      {
        path: '/filling/fillFirst', //首次填报
        name: 'fillFirst',
        component: () => import('@/views/dataFilling/firstFill.vue')
      },
      {
        path: '/filling/addFillType', //填报方式---重要填报
        name: 'addFillType',
        component: () => import('@/views/dataFilling/addFillType.vue')
      },
      {
        path: '/filling/addComFillType', //填报方式 ---一般填报
        name: 'addComFillType',
        component: () => import('@/views/dataFilling/addComFillType.vue')
      },
      {
        path: '/filling/selectLevel', //填报方式-常规填报-选择级别
        name: 'selectLevel',
        component: () => import('@/views/dataFilling/selectLevel.vue')
      },
      {
        path: '/filling/comdataDetailsed', //目录填报已完成--一般数据详情
        name: 'fillComDetails',
        component: () => import('@/views/examineList/comdataDetailsed.vue')
      },
      {
        path: '/filling/dataDetailsed', //目录填报已完成--重要数据详情
        name: 'fillDetails',
        component: () => import('@/views/examineList/dataDetailsed.vue')
      },



      {
        path: '/examine/examineList', //目录审核列表
        name: 'examineList',
        component: () => import('@/views/examineList/index.vue')
      },
      {
        path: '/examine/dataDetails', //目录审核详情--重要数据
        name: 'dataDetails',
        component: () => import('@/views/examineList/dataDetails.vue')
      },
      {
        path: '/examine/commonDetail', //目录审核详情---一般数据
        name: 'commonDetail',
        component: () => import('@/views/examineList/commonDetail.vue')
      },
      {
        path: '/examine/historyList', //目录审核历史记录
        name: 'historyList',
        component: () => import('@/views/examineList/history/historyList.vue')
      },
      {
        path: '/examine/dataDetailsed', //目录审核详情--重要数据--已完成
        name: 'dataDetailsed',
        component: () => import('@/views/examineList/dataDetailsed.vue')
      },
      {
        path: '/examine/comdataDetailsed', //目录审核详情--一般数据--已完成
        name: 'comdataDetailsed',
        component: () => import('@/views/examineList/comdataDetailsed.vue')
      },
      {
        path: '/examine/columns', //目录审核详情--更多列
        name: 'columns',
        component: () => import('@/views/examineList/columns.vue')
      },
      {
        path: '/directories/datalist', //数据目录
        name: 'datalist',
        component: () => import('@/views/directories/index.vue')
      },
      {
        path: '/directories/comdataDetailsed', //数据目录--一般数据详情
        name: 'direComDetails',
        component: () => import('@/views/examineList/comdataDetailsed.vue')
      },
      {
        path: '/directories/dataDetailsed', //数据目录--重要数据详情
        name: 'direDetails',
        component: () => import('@/views/examineList/dataDetailsed.vue')
      },
      {
        path: '/manage/manageList', //管理配置 
        name: 'manageList',
        component: () => import('@/views/manage/index.vue'),
        redirect:"/manage/manageList/org",
        children:[
            {
              path: '/manage/manageList/org', //管理配置  -- 机构管理
              name: 'orgManage',
              component: () => import('@/views/manage/org.vue')
            },
            {
              path: '/manage/manageList/user', //管理配置  -- 用户管理
              name: 'userManage',
              component: () => import('@/views/manage/user/index.vue')
            },
            {
              path: '/manage/manageList/classify', //管理配置  -- 分类配置
              name: 'classifyManage',
              component: () => import('@/views/manage/classify.vue')
            },
            {
              path: '/manage/manageList/setup', //管理配置  -- 填报设置
              name: 'setupManage',
              component: () => import('@/views/manage/setup.vue')
            },
            {
              path: '/manage/manageList/situation', //管理配置  -- 填报情况
              name: 'situationManage',
              component: () => import('@/views/manage/situation.vue')
            }
        ]
      },
      {
        path: '/news', //消息中心
        name: 'news',
        component: () => import('@/views/news/index.vue')
      },
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/login.vue')
  },
  // {
  //   path: '/callback',
  //   name: 'callback',
  //   component: () => import('../views/callback.vue')
  // }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
