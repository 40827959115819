import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
//iview引入
import ViewUIPlus from 'view-ui-plus';
import 'view-ui-plus/dist/styles/viewuiplus.css';
import { Message } from 'view-ui-plus';
import bus from '@/utils/bus';
//element 引入
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'

import 'amfe-flexible'
import "@/assets/style/global.scss"
import "@/assets/font/iconfont.css";

//在火狐浏览器中兼容
import 'core-js/proposals/relative-indexing-method'

//loading动画
import uLoading from 'u-loading'


import axios from "axios";
import VueAxios from 'vue-axios';
axios.defaults.baseURL = process.env.VUE_APP_URL;
axios.defaults.headers['Content-Type'] = "application/x-www-form-urlencoded";
axios.interceptors.request.use(
  config => {

    let user = JSON.parse(localStorage.getItem('User'));
    if (user && user != null && user != undefined) {
      config.headers.Authorization = 'Bearer ' + user.auth_token;
    }
    return config // 对config处理完后返回，下一步将向后端发送请求
  },
  error => { // 当发生错误时，执行该部分代码
    return Promise.reject(error)
  }
)
//请求响应  401未登录 410账号被封禁
axios.interceptors.response.use(
  response => { // 该处为后端返回整个内容
    let code = response.data.code;
    if (code == 405 || code == 403) {
      //生产环境405用户登录信息失效
      localStorage.clear();
      router.replace('/login');
    }
    if (code === 410) {
      //生产环境410用户账号无权限
      router.replace('/login');
      bus.emit('noPermission')
    }
    return response // 该处将结果返回，下一步可用于前端页面渲染用
  },
  error => {
    let code = error.response.data.code;
    if (code == 405 || code == 403) {
      //生产环境405用户登录信息失效
      localStorage.clear();
      router.replace('/login');
    }
    if (code === 410) {
      //生产环境410用户账号无权限
      router.replace('/login');
      bus.emit('noPermission')
    }
    return Promise.reject(error)
  }
)




// 适配小于1920的情况
let clientWidth = document.documentElement.clientWidth
if (clientWidth < 1920) {
  require('@/utils/rem.js')
}

//全局通信
import mitt from "mitt"
// .use(ViewUIPlus)
let app = createApp(App);
app.use(router).use(ViewUIPlus).use(ElementPlus).use(uLoading).mount('#app');
app.use(VueAxios, axios);
app.provide('axios', app.config.globalProperties.axios);
app.config.globalProperties.$Bus = mitt();
